import axios from 'axios';
import { ToastNotification } from '@/scripts/utils';
import { ToastProgrammatic as Toast } from 'buefy';

const DefaultState = () => {
    return {
        all_users: []
    };
},
      state = DefaultState(),
      getters = {
          all_users: state => state.all_users
      },
      mutations = {
          setUsers(state, payload) {
              state.all_users = payload;
          }
      },
      actions = {
          async login({ commit, dispatch, rootState}, payload) {
              await axios.post(`${rootState.global.base_url}api/auth/login`, payload)
                  .then(res => {
                      if (res.data.status == 200) {
                          dispatch('global/toastNotification', {message: `Bienvenido ${res.data.profile.name} ${res.data.profile.lastname}`, type: 'is-success'}, {root: true});
                          dispatch('global/setAuthData', {data: res.data}, {root: true});
                          dispatch('global/setCloseModal', {}, {root: true});
                      } else {
                          dispatch('global/toastNotification', {message: res.data.msg, type: 'is-danger'}, {root: true});
                      }
                  })
                  .catch(err => {
                      console.log(err);
                  });
          },
          async getAllUsers({ commit, dispatch, rootState }) {
              if (!(rootState.global.base_url)) {
                  dispatch('global/setBaseUrl', {}, {root: true});
              }
              await axios.get(`${rootState.global.base_url}api/auth/users`, rootState.global.headers)
                  .then(res => {
                      if (res.data.status == 200) {
                          commit('setUsers', res.data.data);
                          dispatch('global/toastNotification', {message: 'Usuarios registrados', 'type': 'is-success'}, {root: true});
                      } else {
                          dispatch('global/toastNotification', {message: 'Ha ocurrido un error', type: 'is-danger'}, {root: true});
                      }
                  })
                  .catch(err => console.error(err));
          },
          async createUser({ commit, dispatch, rootState}, payload) {
              await axios.post(`${rootState.global.base_url}api/auth/register`, payload)
                  .then(res => {
                      if (res.data.status == 200) {
                          dispatch('global/toastNotification', {message: 'El usuario ha sido registrado exitosamente', type: 'is-success'}, {root: true});
                          dispatch('getAllUsers');
                          dispatch('global/setCloseModal', {}, {root: true});
                      } else {
                          dispatch('global/toastNotification', {message: res.data.message, type: 'is-danger'}, {root: true});
                      }
                  })
                  .catch(err => {
                      console.log(err);
                  });
          },
          async updateUser({ commit, dispatch, rootState}, payload) {
              await axios.put(`${rootState.global.base_url}api/auth/user`, payload, rootState.global.headers)
                  .then(res => {
                      if (res.data.status == 200) {
                          dispatch('global/toastNotification', {message: 'El usuario ha sido actualizado exitosamente', type: 'is-success'}, {root: true});
                          dispatch('getAllUsers');
                          dispatch('global/setCloseModal', {}, {root: true});
                      } else {
                          dispatch('global/toastNotification', {message: res.data.message, type: 'is-danger'}, {root: true});
                      }
                  })
                  .catch(err => {
                      console.log(err);
                  });
          },
          async deleteUser({ commit, dispatch, rootState}, payload) {
              await axios.delete(`${rootState.global.base_url}api/auth/user`, {data : payload, ...rootState.global.headers})
                  .then(res => {
                      if (res.data.status == 200) {
                          dispatch('global/toastNotification', {message: 'El usuario ha sido eliminado exitosamente', type: 'is-success'}, {root: true});
                          dispatch('getAllUsers');
                      } else {
                          dispatch('global/toastNotification', {message: res.data.message, type: 'is-danger'}, {root: true});
                      }
                  })
                  .catch(err => {
                      console.log(err);
                  });
          },
          async updatePassword({ commit, dispatch, rootState}, payload) {
              await axios.put(`${rootState.global.base_url}api/auth/user/password/update`, payload, rootState.global.headers)
                  .then(res => {
                      if (res.data.status == 200) {
                          dispatch('global/toastNotification', {message: 'La contraseña ha sido actualizada exitosamente', type: 'is-success'}, {root: true});
                          dispatch('getAllUsers');
                          dispatch('global/setCloseModal', {}, {root: true});
                      } else {
                          dispatch('global/toastNotification', {message: res.data.message, type: 'is-danger'}, {root: true});
                      }
                  })
                  .catch(err => {
                      console.log(err);
                  });
          },
      };


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
