<template lang="pug">
div#app
  router-view
</template>

<script>
 import { mapActions } from 'vuex';
 export default {
     created() {
         this.loadInitialData()
     },
     methods: {
         ...mapActions('admin',[
             'getConfig'
         ]),
         ...mapActions('global', [
             'setBaseUrl',
         ]),
         async loadInitialData() {
             await this.setBaseUrl()
             await this.getConfig()
         }
     }
 }
</script>

<style lang="scss">
 @import "@/assets/scss/aseguradoos.scss";
 #app {
   font-family: 'Myna', sans-serif;
   font-family: 'Myna Condensed', sans-serif;
   font-family: 'Myna Expanded', sans-serif;
   font-size: 16px !important;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-align: center;
   color: #2c3e50;
 }
 nav {
   padding: 30px;
   a {
     font-weight: bold;
     color: #2c3e50;
     &.router-link-exact-active {
       color: #42b983;
     }
   }
 }
</style>
