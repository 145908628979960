import Vue from 'vue'
import Vuex from 'vuex'

import global from './modules/global';
import web from './modules/web';
import admin from './modules/admin';
import users from './modules/users';
import rus from './modules/rus';
import infoauto from './modules/infoauto';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        global,
        admin,
        web,
        users,
        rus,
        infoauto
    }
})
