<template lang="pug">
transition(name="fade")
  b-modal(:active="true" :title="title" :on-cancel="() => $emit('closeModal')")
     #custom-modal
       #custom-modal-title
         .title.is-4 {{title}}
       #custom-modal-content
         slot(name="content")
</template>

<script>
 export default {
     name: 'ModalComponent',
     props:['title']
 }
</script>
