import { ToastProgrammatic as Toast } from 'buefy';
import axios from 'axios';

export const ToastNotification = (type, message) => {
    Toast.open({
        duration: 3000,
        type,
        position: 'is-top',
        message,
        icon: 'alert'
    });
};

export const Interceptor = () => {
    axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response.status === 401) {
                window.location.href = '/web/sessionexpired';
            }
        });
};

export const Months = {
    0: 'Enero',
    1: 'Febrero',
    2: 'Marzo',
    3: 'Abril',
    4: 'Mayo',
    5: 'Junio',
    6: 'Julio',
    7: 'Agosto',
    8: 'Septiembre',
    9: 'Octubre',
    10: 'Noviembre',
    11: 'Diciembre',
};
