<template lang="pug">
div
  b-field(grouped expanded)
    b-field(
      label="Nombre completo"
      label-position="inside"
      expanded
      :type="Object.keys(errors).includes('name') ? 'is-danger' : ''"
      :message="Object.keys(errors).includes('name') ? errors.name : ''"
      @keydown.native="removeError('name')")
      b-input(icon="account" type="text" placeholder="Nombre completo" v-model="message.name")
    b-field(
      label="Telefono"
      label-position="inside"
      expanded
      :type="Object.keys(errors).includes('phone') ? 'is-danger' : ''"
      :message="Object.keys(errors).includes('phone') ? errors.phone : ''"
      @keydown.native="removeError('phone')")
      b-input(icon="phone" type="text" placeholder="Telefono" v-model="message.phone")
  b-field(grouped)
      b-field(
        label="E-mail"
        label-position="inside"
        expanded
        :type="Object.keys(errors).includes('email') ? 'is-danger' : ''"
        :message="Object.keys(errors).includes('email') ? errors.email : ''"
        @keydown.native="removeError('email')")
        b-input(icon="at" placeholder="E-mail" v-model="message.email")
      b-field(
        label="Provincia"
        label-position="inside"
        expanded
        :type="Object.keys(errors).includes('city') ? 'is-danger' : ''"
        :message="Object.keys(errors).includes('city') ? errors.city : ''"
        @keydown.native="removeError('city')")
        b-input(icon="map-marker-account-outline" placeholder="Provincia" v-model="message.city")
  b-field(grouped)
    b-field(label="Tipo de consulta" expanded)
      b-field(grouped expanded)
        b-field(expanded)
          b-radio(v-model="message.message_type" native-value="Seguros") Seguros
        b-field(expanded)
          b-radio(v-model="message.message_type" native-value="Siniestros") Siniestros
    b-field()
      b-field(grouped expanded)
        b-field(label="Tiene un seguro con nosotros?")
          b-switch(v-model="message.is_client")
        b-field(label="Su consulta es urgente?")
          b-switch(v-model="message.is_urgent")
  b-field(
    label="Mensaje"
    label-position="inside"
    expanded
    :type="Object.keys(errors).includes('message') ? 'is-danger' : ''"
    :message="Object.keys(errors).includes('message') ? errors.message : ''"
    @keydown.native="removeError('message')")
    b-input(type="textarea" v-model="message.message")
  br
  b-button.as-btn-primary.is-pulled-right(icon-left="email-arrow-right" @click="newMessage") Enviar
  br
</template>

<script>
 import { mapActions } from 'vuex';

 export default {
     data() {
         return {
             message: {
                 name: '',
                 phone: '',
                 email: '',
                 city: '',
                 message_type: 'Seguros',
                 is_client: false,
                 is_urgent: false,
                 message: ''
             },
             errors:{}
         }
     },
     methods: {
         ...mapActions('web', [
             'sendMessage'
         ]),
         ...mapActions('global', [
             'toastNotification'
         ]),
         validateForm() {
             this.errors = {};
             if (!this.message.name.length) {
                 this.errors.name = 'El nombre no puede estar vacio'
             }
             if (!this.message.email.length) {
                 this.errors.email = 'El email no puede estar vacio'
             }
             if (!this.message.phone.length) {
                 this.errors.phone = 'El telefono no puede estar vacio'
             }
             if (!this.message.city.length) {
                 this.errors.city = 'Debe ingresar una ciudad'
             }
             if (!this.message.message.length) {
                 this.errors.message = 'El mensaje no puede estar vacio'
             }
         },
         newMessage() {
             this.validateForm()
             if (!Object.keys(this.errors).length) {
                 this.sendMessage(this.message)
             } else {
                 this.toastNotification({type: 'is-danger', message: 'Ha ocurrido un error, por favor revise el formulario'})
             }
         },
         removeError(error) {
             delete this.errors[error]
         }
     }
 }
</script>
