<template lang="pug">
div#admin-layout
  .columns
    .column.is-3
     b-sidebar(
       :fullheight="true"
       :can-cancel="false"
       v-model="open")
       .p-1
         br
         a(@click="$router.push('/web').catch(()=>{})")
           img(
             :src="require('@/assets/logo.png')"
             alt="Aseguradoos - Marketplace de seguros")
       b-menu
         b-menu-list(label="Menu")
           b-menu-item(
             label="Inicio"
             icon="home"
             @click="$router.push('/admin').catch(() => {})")
           b-menu-item(
             label="Productos"
             icon="human-dolly"
             @click="$router.push('/admin/productos').catch(() => {})")
           b-menu-item(
             label="Siniestros"
             icon="hand-heart"
             @click="$router.push('/admin/siniestros').catch(()=>{})")
           b-menu-item(
             label="Nosotros"
             icon="account-multiple"
             @click="$router.push('/admin/about').catch(()=>{})")
           b-menu-item(
             label="Terminos y condiciones"
             icon="file-sign"
             @click="$router.push('/admin/terms').catch(()=>{})")
           b-menu-item(
             label="Politicas de privacidad"
             icon="text-box-search"
             @click="$router.push('/admin/privacy').catch(()=>{})")
           b-menu-item(
             label="Condiciones generales"
             icon="text-box-multiple"
             @click="$router.push('/admin/conditions').catch(()=>{})")
           b-menu-item(
             label="Politicas de seguridad"
             icon="security"
             @click="$router.push('/admin/security').catch(()=>{})")
           b-menu-item(
             label="FAQ"
             icon="frequently-asked-questions"
             @click="$router.push('/admin/faq').catch(()=>{})")
           b-menu-item(
             label="Mensajes"
             icon="email-outline"
             @click="$router.push('/admin/messages').catch(()=>{})")
           b-menu-item(
             label="Solicitudes"
             icon="file-sign"
             @click="$router.push('/admin/requests').catch(()=>{})")
           b-menu-item(
             label="Usuarios"
             icon="account-multiple"
             @click="$router.push('/admin/usuarios').catch(()=>{})")
           b-menu-item(label="Salir" icon="logout" @click="closeSession")
    .column.is-9
     .content
       router-view
</template>

<script>
 import { mapActions, mapGetters } from 'vuex';

 export default {
     data() {
         return {
             open:true
         }
     },
     created() {
         this.loadInitialData();
     },
     computed: {
         ...mapGetters('global', [
             'auth'
         ])
     },
     methods: {
         ...mapActions('admin', [
             'getProductsForAdmin',
             'getConfig'
         ]),
         ...mapActions('global', [
             'setBaseUrl',
             'toastNotification',
             'finishSession',
             'checkSession'
         ]),
         async loadInitialData() {
             await this.setBaseUrl();
             await this.checkSession();
             await this.getConfig();
             if (!this.auth.isLoggedIn) {
                 this.$router.push('/')
                 this.toastNotification({message: 'Usted no esta autorizado', type: 'is-danger'})
             }
             await this.getProductsForAdmin();
         },
         closeSession() {
             this.$router.push('/')
             this.toastNotification({message: 'La sesion se ha cerrado', type: 'is-success'})
             this.finishSession();
         }
     }
 }
</script>

<style lang="scss">
 #admin-layout {
   .sidebar {
     min-height: 100vh;
     background-color: red
   }
   .content {
     padding: 5rem 5rem;
     min-height: 100vh;
     overflow: hidden !important;
   }
 }
 .p-1 {
   text-align: center
 }
 .b-sidebar .sidebar-content {
   background-color: #1E1E1E !important;
   min-width: 26% !important;
   .menu {
     padding: 3em;
     .menu-label {
       color: #fff;
     }
     ul.menu-list {
       li {
         a {
           color: #fff;
           &:hover {
             background-color: #ff6600
           }
           &.is-active {
             background-color: #ff6600
           }
         }
       }
     }
   }
 }
</style>
