<template lang="pug">
div
  b-icon(icon="shield-account-outline" size="is-large")
  br
  br
  b-field(label="Usuario" expanded)
     b-input(icon="account" placeholder="Usuario" v-model="username" @keyup.enter.native="requestLogin")
  b-field(label="Contraseña" expanded)
     b-input(icon="lock" type="password" placeholder="Contraseña" v-model="password" @keyup.enter.native="requestLogin")
  br
  b-button.as-btn-primary(icon-left="account-arrow-right-outline" expanded @click="requestLogin") Ingresar
</template>

<script>
 import { mapActions, mapGetters }  from 'vuex';

 export default {
     data() {
         return {
             username: null,
             password: null
         }
     },
     methods: {
         ...mapActions('users', [
             'login'
         ]),
         ...mapActions('global', [
             'toastNotification'
         ]),
         requestLogin() {
             if (!this.username || !this.password) {
                 this.toastNotification({message:'Debe ingresar un usuario y contraseña validos.', type: 'is-danger'});
             } else {
                 this.login({username: this.username, password: this.password});
                 this.toastNotification({message:'Ingresando...', type: 'is-success'});
             }

         }
     }
 }
</script>
