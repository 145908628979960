<template lang="pug">
div
  navbar-component
  router-view
  footer-component
  modal-component(
    title="Hacenos tu consulta!"
    v-if="openContactModal"
    @closeModal="toggleContactModal(false)")
    template(v-slot:content)
     contact-form-component
  modal-component#login-form(
    title="Ingresar al sistema"
    v-if="openAuthModal"
    @closeModal="toggleAuthModal(false)")
    template(v-slot:content)
     login-form-component
     hr
     p Si aun no tiene cuenta...
     br
     b-button.as-btn-primary(icon-left="account-plus-outline" expanded @click="showRegistrationModal") Registrarse
  modal-component(
    title="Crear nuevo usuario..."
    v-if="openRegistrationModal"
    @closeModal="toggleRegistrationModal(false)")
    template(v-slot:content)
      register-form-component(@closeModal="toggleRegistrationModal(false)")
</template>

<script>
 import { mapGetters, mapActions } from 'vuex';
 import NavbarComponent from '@/components/NavbarComponent';
 import FooterComponent from '@/components/FooterComponent';
 import ModalComponent from '@/components/ModalComponent';
 import ContactFormComponent from '@/components/ContactFormComponent';
 import LoginFormComponent from '@/components/LoginFormComponent';
 import RegisterFormComponent from '@/components/RegisterFormComponent';

 export default {
     components: {
         NavbarComponent,
         FooterComponent,
         ModalComponent,
         ContactFormComponent,
         LoginFormComponent,
         RegisterFormComponent
     },
     watch: {
         $route(to, from) {
             if (to != from) {
                 window.scrollTo({ top: 0, behavior: 'smooth' });
             }
         },
         close_modal(nv) {
             if (nv) {
                 this.toggleAuthModal(false);
                 this.toggleContactModal(false);
             }
         }
     },
     mounted() {
         this.loadInitialData()
     },
     computed: {
         ...mapGetters('web', [
             'openContactModal',
             'openAuthModal',
             'openRegistrationModal',
         ]),
         ...mapGetters('global', [
             'close_modal'
         ])
     },
     methods: {
         ...mapActions('web', [
             'toggleContactModal',
             'toggleAuthModal',
             'toggleRegistrationModal',
             'getConfig'
         ]),
         ...mapActions('global', [
             'setBaseUrl'
         ]),
         showRegistrationModal() {
             this.toggleAuthModal(false);
             this.toggleRegistrationModal(true);
         },
         async loadInitialData() {
             await this.setBaseUrl();
             await this.getConfig();
         }
     }
 }
</script>

<style lang="scss">
 #login-form {
   .modal-content {
     border-radius: 10px !important;
     width: 400px !important;
   }
 }
</style>
