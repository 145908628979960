import axios from 'axios';
import { ToastNotification } from '@/scripts/utils';

const DefaultState = () => {
    return {
        infoauto_token: null,
        rus_car_url: 'https://sis.rus.com.ar/api-rus/cotizaciones/autosconriesgo',
        rus_token: null,
        rus_headers: {
            headers: {
                Authorization: null
            }
        },
        selected_car: {},
        rus_quotes: [],
        elnorte_quotes: [],
        rus_item: null,
        elnorte_item: null,
        brands: [],
        models: [],
        localidades: [],
        features: [],
        request_status: 'waiting',
        is_moto: false,
        search_active: false,
        selected_quote: [],
        open_detail_modal: false,
        display_quote_preview: false,
        response_await: false
    };
},
      state = DefaultState(),
      getters = {
          infoauto_token: state => state.infoauto_token,
          brands: state => state.brands,
          features: state => state.features,
          models: state => state.models,
          request_status: state => state.request_status,
          rus_quotes: state => state.rus_quotes,
          elnorte_quotes: state => state.elnorte_quotes,
          selected_car: state => state.selected_car,
          search_active: state => state.search_active,
          selected_quote: state => state.selected_quote,
          open_detail_modal: state => state.open_detail_modal,
          display_quote_preview: state => state.display_quote_preview,
          elnorte_item: state => state.elnorte_item,
          response_await: state => state.response_await
      },
      mutations = {
          setInit(state, payload) {
              state.brands = payload.brands;
              state.infoauto_token = payload.infoauto_token;
              state.rus_token = payload.rus_token;
              state.rus_headers.headers['Authorization'] = 'Bearer ' + payload.rus_token;
              state.rus_code = payload.rus_code;
              state.features = payload.features;
              localStorage.setItem('infoauto_token', payload.infoauto_token);
              localStorage.setItem('rus_token', payload.rus_token);
          },
          setModels(state, payload) {
              state.models = payload;
          },
          setRusQuotes(state, payload) {
              state.rus_quotes = payload;
              if (payload.length) {
                  state.rus_item = payload[0].sumaAsegurada;
              }
          },
          setElnorteQuotes(state, payload) {
              if (payload.data) {
                  const cleanData = JSON.parse(JSON.stringify(payload.data));
                  if (cleanData.count != 0) {
                      state.elnorte_item = cleanData.cotizacion.sumaAsegurada;
                      state.elnorte_quotes = Object.values(cleanData.items);
                  }
              } else {
                  state.elnorte_item = null;
                  state.elnorte_quotes = [];
              }
          },
          setRequestStatus(state, payload) {
              state[`request_status_${payload.company}`] = payload.status;
          },
          setSelectedCar(state, payload) {
              state.selected_car = JSON.parse(JSON.stringify(payload));
          },
          setSearchActive(state, payload) {
              state.search_active = payload;
          },
          setSelectedQuote(state, payload) {
              state.selected_quote = payload;
          },
          setOpenDetailModal(state, payload) {
              state.open_detail_modal = payload;
          },
          toggleQuotePreview(state, payload) {
              state.display_quote_preview = payload;
          },
          setResponseAwait(state, payload) {
              state.response_await = payload;
          }
      },
      actions = {
          toggleQuotePreview({commit}, payload) {
              commit('toggleQuotePreview', payload);
          },
          setSelectedQuote({ commit }, payload) {
              commit('setSelectedQuote', payload);
          },
          setOpenDetailModal({ commit }, payload) {
              commit('setOpenDetailModal', payload);
          },
          setSearchActive({ commit }, payload) {
              commit('setSearchActive', payload);
          },
          async initialLoad({ commit, rootState }) {
              await axios.get(`${rootState.global.base_url}api/infoauto/brands`)
                  .then(res => {
                      commit('setInit', res.data);
                  });
          },
          async getModels({ commit, rootState }, payload) {
              await axios.post(`${rootState.global.base_url}api/infoauto/models`, payload)
                  .then(res => {
                      commit('setModels', res.data);
                  });
          },
          async rusSendCarQuoteRequest({ commit, state, dispatch, rootState}, payload) {
              commit('setRusQuotes', []);
              payload.data['codigoProductor'] = state.rus_code;
              commit('setRequestStatus', {'company': 'rus', 'status': 'loading'});
              await axios.post(`${rootState.global.base_url}api/cotizar/autos/rus`, payload)
                  .then(res => {
                      if (res.data.success) {
                          let data = JSON.parse(JSON.stringify(res.data.quotes));
                          commit('setRusQuotes', data);
                      }
                      commit('setRequestStatus', {'company': 'rus', 'status': 'waiting'});
                  })
                  .catch(err => {
                      commit('setRequestStatus', {'company': 'rus', 'status': 'error'});
                  });
              commit('setSearchActive', false);
              commit('setRequestStatus', {'company': 'rus', 'status': 'waiting'});
              ToastNotification();
              dispatch('global/toastNotification', {message: `${state.rus_quotes.length} opciones encontradas para RUS`, type:'is-success'}, {root: true});
          },
          async elnorteSendCarQuoteRequest({ commit, state, dispatch, rootState}, payload) {
              commit('setElnorteQuotes', []);
              commit('setRequestStatus', {'company': 'elnorte', 'status': 'loading'});
              await axios.post(`${rootState.global.base_url}api/cotizar/autos/elnorte`, payload)
                  .then(res => {
                      commit('setElnorteQuotes', res.data);
                  })
                  .catch(err => {
                      console.log('error', err);
                  });
              commit('setSearchActive', false);
              commit('setRequestStatus', {'company': 'elnorte', 'status': 'waiting'});
              dispatch('global/toastNotification', {message: `${state.elnorte_quotes.length} opciones encontradas para El Norte`, type:'is-success'}, {root: true});
          },
          setSelectedCar({ commit }, payload) {
              commit('setSelectedCar', payload);
          },
          async submitQuoteRequest({ commit, state, dispatch, rootState}, payload) {
              commit('setResponseAwait', true);
              await axios.post(`${rootState.global.base_url}/api/web/requests/`,payload ,state.credentials)
                  .then(res => {
                      dispatch('global/dialogNotification', {title:'Operacion realizada correctamente', type: 'is-success', message: `Su solicitud ha sido enviada correctamente, nos comunicaremos a la brevedad. Muchas gracias!.`}, {root: true});
                      commit('toggleQuotePreview', false);
                  })
                  .catch(err => console.log(err));
              commit('setResponseAwait', false);
          }
      };

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
