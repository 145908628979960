<template lang="pug">
</template>

<script>
 export default {
     name: 'HomeView',
     mounted() {
         this.$router.push('/web').catch(()=>{})
     }
 }
</script>
