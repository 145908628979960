import axios from 'axios';
import { ToastNotification } from '@/scripts/utils';
import { ToastProgrammatic as Toast } from 'buefy';

const DefaultState = () => {
    return {
        products: [],
        productOptions: [],
        issuesOptions: [],
        openContactModal: false,
        openAuthModal: false,
        openRegistrationModal: false,
        quote:[],
        config: [],
        faqs: [],
        issues: [],
        options: [
            {
                name: 'Inicio',
                uri: '/',
                has_option: false,
                options: {}
            },
            {
                name: 'Nosotros',
                uri: '/web/about',
                has_option: false,
                options: {}
            },
            {
                name: 'Productos',
                uri: '/productos',
                has_option: true,
                options: {}
            },
            {
                name: 'Siniestros',
                uri: '/web/siniestros',
                has_option: true,
                options: {}
            },
            {
                name: 'IGS',
                uri: '/web/igs',
                has_option: false,
                options: {}
            },
            {
                name: 'FAQs',
                uri: '/web/faqs',
                has_option: false,
                options: {}
            }
        ]
    };
},
      state = DefaultState(),
      getters = {
          products: state => state.products,
          productOptions: state => state.productOptions,
          options: state => state.options,
          openContactModal: state => state.openContactModal,
          openAuthModal: state => state.openAuthModal,
          openRegistrationModal: state => state.openRegistrationModal,
          config: state => state.config,
          faqs: state => state.faqs,
          issues: state => state.issues,
          issuesOptions: state => state.issuesOptions,
          quote: state => state.quote,
      },
      mutations = {
          setProducts(state, payload) {
              state.products = payload;
          },
          setProductOptions(state) {
              state.productOptions = [];
              state.products.forEach(product => {
                  if (Object.keys(product).includes('title')) {
                      state.productOptions.push({
                          'id': product.id,
                          'name': product.title,
                          'path': `/web/productos/${product.id}`
                      })
                  }
              });
              state.productOptions.sort((a,b) => b.name.toLowerCase() < a.name.toLowerCase());
          },
          setIssuesOptions(state) {
              state.issuesOptions = [];
              state.issues.forEach(issue => {
                  if (Object.keys(issue).includes('title')) {
                      state.issuesOptions.push({
                          'id': issue.id,
                          'name': issue.title,
                          'path': `/web/siniestros/${issue.id}`
                      })
                  }
              });
              state.issuesOptions.sort((a,b) => b.name.toLowerCase() < a.name.toLowerCase());
          },
          toggleContactModal(state, payload) {
              state.openContactModal = payload;
          },
          toggleAuthModal(state, payload) {
              state.openAuthModal = payload;
          },
          toggleRegistrationModal(state, payload) {
              state.openRegistrationModal = payload;
          },
          setConfig(state, payload) {
              state.config = payload;
          },
          setIssues(state, payload) {
              state.issues = payload
          },
          setFaqs(state, payload) {
              state.faqs = payload;
          },
          setQuote(state, payload) {
              state.quote = payload;
          }
      },
      actions = {
          async getProducts({ commit, dispatch, rootState }) {
              if (!(rootState.global.base_url)) {
                  dispatch('global/setBaseUrl', {}, {root: true})
              }
              await axios.get(`${rootState.global.base_url}api/web/products`)
                  .then(async res => {
                      await commit('setProducts', res.data.data)
                  })
                  .catch(err => {
                      console.log(err);
                  })
          },
          async getConfig({commit, dispatch, rootState}) {
              await axios.get(`${rootState.global.base_url}api/admin/config`)
                  .then(res => {
                      commit('setConfig', res.data)
                  })
                  .catch(err => console.log(err))
          },
          async getIssues({ commit, dispatch, rootState}) {
              await axios.get(`${rootState.global.base_url}api/admin/accidents`, rootState.global.headers)
                  .then(res => {
                      commit('setIssues', res.data.data)
                  })
                  .catch(err => console.log(err))
          },
          async getFaqs({ commit, dispatch, rootState}) {
              await axios.get(`${rootState.global.base_url}api/admin/faqs`, rootState.global.headers)
                  .then(res => {
                      commit('setFaqs', res.data.data)
                  })
                  .catch(err => console.log(err))
          },
          async getQuote({ commit, dispatch, rootState}, payload) {
              await axios.post(`${rootState.global.base_url}api/web/quote/update`, {id: payload})
                  .then(res => {
                      commit('setQuote', res.data.data)
                  })
                  .catch(err => console.log(err))
          },
          async sendMessage({commit, dispatch, rootState}, payload) {
              await axios.post(`${rootState.global.base_url}api/web/message`, payload)
                  .then(res => {
                      if (res.data.status == 201) {
                          dispatch('global/setCloseModal', {}, {root:true})
                          dispatch('global/toastNotification', {type: 'is-success', message:'El mensaje se ha enviado correctamente'}, {root: true})
                      } else {
                          dispatch('global/toastNotification', {type: 'is-danger', message:'ha ocurrido un error, por favor intente nuevamente.'}, {root: true})                            }

                  })
                  .catch(err => console.log(err))
          },
          async updateQuoteRequest({ commit, dispatch, rootState}, payload) {
              await axios.put(`${rootState.global.base_url}api/web/requests`, payload, rootState.global.headers)
                  .then(res => {
                      console.log(res);
                  })
                  .catch(err => {console.log(err);});
          },
          async setProductOptions({ commit }) {
              commit('setProductOptions')
          },
          async setIssuesOptions({ commit }) {
              commit('setIssuesOptions')
          },
          toggleContactModal({ commit }, payload) {
              commit('toggleContactModal', payload)
          },
          toggleAuthModal({ commit }, payload) {
              commit('toggleAuthModal', payload)
          },
          toggleRegistrationModal({ commit }, payload) {
              commit('toggleRegistrationModal', payload)
          }
      };

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
