import WebLayout from '../../layouts/WebLayout';

const web = [
    {
        path: '/',
        component: WebLayout,
        children: [
            {
                path: '/web',
                name: 'web',
                component: () => import('@/views/web/IndexView.vue')
            },
            {
                path: '/web/productos/:product',
                name: 'products',
                component: () => import('@/views/web/ProductView.vue')
            },
            {
                path: '/web/terminos/:option',
                name: 'terms',
                component: () => import('@/views/web/TermsView.vue')
            },
            {
                path: '/web/about',
                name: 'about',
                component: () => import('@/views/web/AboutView.vue')
            },
            {
                path: '/web/igs',
                name: 'igs',
                component: () => import('@/views/web/IgsView.vue')
            },
            {
                path: '/web/siniestros/:issue',
                name: 'siniestros',
                component: () => import('@/views/web/IssuesView.vue')
            },
            {
                path: '/web/faqs',
                name: 'faqs',
                component: () => import('@/views/web/FaqsView.vue')
            },
            {
                path: '/web/cotizadores/rus/autos',
                name: 'ruscars',
                component: () => import('@/views/integrations/rusCarIntegration.vue')
            },
            {
                path: '/web/cotizadores/autos',
                name: 'cotizador_autos',
                component: () => import('@/views/web/CotizadorAutosView.vue')
            },
            {
                path: '/web/cotizadores/rus/motos',
                name: 'rusmotos',
                component: () => import('@/views/integrations/rusMotoIntegration.vue')
            },
            {
                path: '/web/forms/quote/:id',
                name: 'quote-form',
                component: () => import('@/views/web/forms/ExtrasView.vue')
            },
            {
                path: '/web/sessionexpired',
                name: 'expiredsession',
                component: () => import('@/views/SessionExpiredView.vue')
            },
            {
                path: '/web/*',
                name: 'Not Found',
                component: () => import('@/views/default/NotFoundView.vue')
            },
        ]
    }
]

export default web
