<template lang="pug">
div
  b-field(grouped expanded)
    b-field(label="Nombre de usuario" expanded)
      b-input(icon="account" type="text" placeholder="Nombre de usuario")
    b-field(label="Nombre/s" expanded)
      b-input(icon="account" type="text" placeholder="Nombre completo")
    b-field(label="Apellido/s" expanded)
      b-input(icon="account" type="text" placeholder="Nombre completo")
  b-field(grouped)
    b-field(label="Telefono" expanded)
      b-input(icon="phone" type="text" placeholder="Telefono")
    b-field(label="E-mail" expanded)
      b-input(icon="at" placeholder="E-mail")
    b-field(label="Provincia" expanded)
      b-input(icon="map-marker-account-outline" placeholder="Provincia")
  br
  b-button.as-btn-primary.is-pulled-right(icon-left="account-plus-outline") Crear
  span.is-pulled-right &nbsp&nbsp
  b-button.is-danger.is-pulled-right(icon-left="close" @click="$emit('closeModal')") Cancelar
  br
</template>

<script>
 export default {

 }
</script>
