<template lang="pug">
div#footer
  #footer-contact
    .columns
      .column.is-two-thirds
        .title.is-3 Hacenos tu consulta!
        p Asegurados te cuida siempre, donde nos necesites y con la mayor seguridad!
      .column.is-one-third.has-text-centered
        b-button#footer-contact-button.has-text-centered.as-btn-primary-light.is-medium(icon-left="email-outline" @click="toggleContactModal(true)") Contactanos
  .container
    .columns.is-mobile.is-multiline
      .column.is-3-desktop.is-full-touch
        img(:src="require('@/assets/logo.png')" alt="Aseguradoos")
        ul
          li
            router-link(:to="{path: '/web/terminos/terms'}") Terminos y condiciones
          li
            router-link(:to="{path: '/web/terminos/privacy'}") Politicas de privacidad
          li
            router-link(:to="{path: '/web/terminos/security'}") Politicas de seguridad
          li
            router-link(:to="{path: '/web/terminos/conditions'}") Condiciones generales
          li
             router-link(:to="{path: '/web/about'}") Nosotros
        br
        b-button.as-btn-primary(icon-left="logout" @click="logout" v-if="auth.isLoggedIn" expanded size="is-small") Salir
        b-button.as-btn-primary(icon-left="login" @click="toggleAuthModal(true)" v-else expanded size="is-small") Ingresar
      .column.is-3-desktop.is-full-touch
        .title.is-5 Info de Contacto
        ul
          li
            b-icon(pack="mdi" icon="map-search-outline" size="is-small")
            span &nbsp&nbsp Av Figueroa Alcorta 180 10 “D”
          li
            b-icon(pack="mdi" icon="map-search-outline" size="is-small")
            span &nbsp&nbsp Bº Centro - Córdoba (Capital)
          li
            b-icon(pack="mdi" icon="whatsapp" size="is-small")
            span &nbsp&nbsp WhatsApp: +54 (351) 340-2715
          li
            b-icon(pack="mdi" icon="whatsapp" size="is-small")
            span &nbsp&nbsp WhatsApp: +54 (343) 698-9910
          li
            b-icon(pack="mdi" icon="phone" size="is-small")
            span &nbsp&nbsp Telefono: (351) 739-5328
          li
            b-icon(pack="mdi" icon="phone" size="is-small")
            span &nbsp&nbsp Administracion: (351) 739-5328
          li
            b-icon(pack="mdi" icon="email" size="is-small")
            span &nbsp&nbsp eMail: info@seguradoos.com
      .column.is-3-desktop.is-full-touch
        .title.is-5 Productos
        ul
          li(v-for="(option, key) in productOptions")
            router-link(:to="{path: option.path}") {{option.name}}
      .column.is-3-desktop.is-full-touch
        .title.is-5 Seguinos
        a(href="https://www.instagram.com/aseguradoos/" target="_blank")
          b-icon(pack="mdi" icon="instagram")
        span &nbsp&nbsp
        a(href="https://www.linkedin.com/company/aseguradoos/" target="_blank")
          b-icon(pack="mdi" icon="linkedin")
        span &nbsp&nbsp
        a(href="https://twitter.com/aseguradoos" target="_blank")
          b-icon(pack="mdi" icon="twitter")
        span &nbsp&nbsp
        a(href="https://www.facebook.com/aseguradoos" target="_blank")
          b-icon(pack="mdi" icon="facebook")
        br
        br
        br
        b-button.as-btn-primary(icon-left="email-alert-outline" @click="openNewsModal=true" expanded) Quiero recibir novedades!
  modal-component(
    title="Recibir novedades!"
    v-if="openNewsModal"
    @closeModal="openNewsModal=false")
    template(v-slot:content)
      form
        b-field(label="Email" label-position="inside")
          b-input(v-model="subscriber.email")
        br
        b-button.as-btn-primary(icon="send" expanded) Subscribir
        br
        b-message(
          type="is-success"
          )
          p.has-text-left Al subscribirse a nuestro sitio para recibir novedades Ud. acepta los terminos, condiciones, politicas de seguridad y confidencialidad detalladas el la seccion correspondiente.
</template>

<script>
 import { mapGetters, mapActions } from 'vuex';
 import ModalComponent from '@/components/ModalComponent';

 export default {
     components: {
         ModalComponent
     },
     data() {
         return {
             openNewsModal: false,
             subscriber: {
                 email: null
             }
         }
     },
     computed: {
         ...mapGetters('web', [
             'productOptions'
         ]),
         ...mapGetters('global', [
             'auth'
         ])
     },
     methods: {
         ...mapActions('web', [
             'toggleContactModal',
             'toggleAuthModal'
         ]),
         ...mapActions('global', [
             'toastNotification',
             'finishSession'
         ]),
         logout() {
             this.$buefy.dialog.confirm({
                 title: 'Cerrar sesion',
                 message: 'Seguro que desea terminar la sesion?.',
                 confirmText: 'Confirmar',
                 cancelText: 'Cancelar',
                 type: 'is-danger',
                 hasIcon: true,
                 onConfirm: () => {
                     this.$router.push('/');
                     this.toastNotification({message: 'La sesion se ha cerrado.', type: 'is-success'});
                     this.finishSession()
                 }
             })
         }
     }
 }
</script>

<style lang="scss">
 #footer {
   margin-top: 7rem;
   color: #fff;
   position: static;
   padding: 3rem 15rem;
   bottom:0;
   min-height: 100px;
   width: 100vw;
   background-color: #1e1e1e;
   &-contact {
     padding: 2rem 3rem;
     margin: -90px auto 50px auto;
     width: 75%;
     background-color: transparent;
     background-image:linear-gradient(130deg, #E2581C 22%, #060709 97%);
     border-radius: 25px;
     box-shadow: 3px 3px 3px #c0c0c0;
     &-button {
       margin: 1rem auto 0rem 3rem !important;
     }
   }
   .title {
     color: #fff !important;
     font-weight: bold
   }
   ul {
     li {
       font-size: 14px !important;
       margin: .5em 0;
       span {
         font-size: 14px !important;
       }
     }
   }
   .column {
     text-align: left !important
   }
   a {
     color: #fff !important;
   }
   a:hover {
     color: #e2581c !important;
   }
   @media screen and (max-width: 1600px) {
     padding: 1rem 6rem
   }
 }
</style>
