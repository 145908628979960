import Vue from 'vue';
import './plugins/axios'
import App from './App.vue';
import router from './router';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css'
import store from './store';
import { Interceptor } from './scripts/utils';
import 'mdi/css/materialdesignicons.min.css';
import '@mdi/font/css/materialdesignicons.min.css';

Vue.config.productionTip = false;
Vue.use(Buefy);
Vue.use(require('vue-moment'));

Interceptor();

new Vue({
    router,
    store,
    render: function (h) { return h(App) }
}).$mount('#app')
